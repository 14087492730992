.services {
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  gap: 15px;
}
.service {
  flex-basis: 30%;
  position: relative;
}
.service img {
  width: 100%;
  display: block;
  border-radius: 10px;
}
.caption {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 15, 152, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  padding-top: 70%;
  transition: 0.4s;
}
.service:hover .caption {
  opacity: 1;
  padding-top: 0%;
}
.caption p {
  line-height: 2;
  font-size: 22px;
}
/* Media Query */
@media (max-width: 850px) {
  .services {
    flex-direction: column;
  }
  .service {
    flex-basis: 100%;
    margin: 20px;
  }
}
