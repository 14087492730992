.gallery {
  margin: 80px auto;
  width: 90%;
  text-align: center;
}
.work {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.work img {
  width: 30%;
  border-radius: 10px;
}
.work video {
  border-radius: 13px;
}
/* Media Query */
@media (max-width: 1000px) {
  .gallery {
    flex-wrap: wrap;
  }
  .work img {
    width: 45%;
    margin-bottom: 20px;
  }
}
@media (max-width: 850px) {
  .gallery {
    flex-wrap: wrap;
  }
  .work img {
    width: 48%;
    margin-bottom: 20px;
  }
}
@media (max-width: 350px) {
  .gallery {
    flex-wrap: wrap;
  }
  .work img {
    width: 48%;
    margin-bottom: 20px;
  }
}
