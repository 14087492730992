.about {
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}
.about-left {
  flex-basis: 40%;
}
.about-right {
  flex-basis: 56%;
}
.about-img {
  width: 100%;
  border-radius: 10px;
}
.about-right h3 {
  font-weight: 600;
  font-size: 16px;
  color: #212ea0;
}
.about-right h2 {
  max-width: 400px;
  margin: 10px 0px;
  font-size: 28px;
  color: #000;
}
.about-right p {
  margin-bottom: 15px;
  color: #676767;
}
/* Media-Query */
@media (max-width: 850px) {
  .about {
    flex-direction: column;
  }
  .about-left {
    flex-basis: 100%;
    margin: 20px;
    order: 2;
  }
  .about-right {
    flex-basis: 100%;
  }
}
