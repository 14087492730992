.title {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 70px 0 30px;
  color: #212ea0;
}
.title h2 {
  font-size: 32px;
  color: #000f38;
  text-transform: none;
  margin-top: 5px;
}
